import { configuration } from "../helpers/config";
import store from '../redux/store';


const { dispatch, getState } = store;
let LOB_VALUE = getState().app.LobValue;


//header function
async function MenuFunction() {
  if(LOB_VALUE==null){
    LOB_VALUE = 1
  }else{
    LOB_VALUE = LOB_VALUE
  }
  try {
    // Fetch data from API
    const response = await fetch(`${configuration.APP_URL_CONTENTS}/menus?lob=${LOB_VALUE}`);
    const data = await response.json();
    const menu = data?.find((item) => item.key === "main_menu");
        
    // Return the data
    return menu;
  } catch (error) {
    console.error('Error fetching menus:', error);
    return null; // Handle error gracefully, return null or some default value
  }
}

//footer function 
async function FooterFunction(LOB_VALUE) {
  if(LOB_VALUE==null){
    LOB_VALUE = 1
  }else{
    LOB_VALUE = LOB_VALUE
  }
  try {
    // Fetch data from API
    let footer = {};
    let widgetmenu = [];
    let datasetting = [];
    try {
      // Fetch menu data
      const response = await fetch(`${configuration.APP_URL_CONTENTS}/menus?lob=${LOB_VALUE}`);
      const data = await response.json();
      footer = data?.find((item) => item.key === "footer");
    } catch (error) {
      console.error("Failed to fetch menu data:", error);
    }

    try {
      // Fetch footer widgets data
      const responsewidget = await fetch(`${configuration.APP_URL_CONTENTS}/widget/footer?lob=${LOB_VALUE}`);
      const datawidget = await responsewidget.json();
      widgetmenu = datawidget && datawidget[0]?.Widget;
    } catch (error) {
      console.error("Failed to fetch footer widget data:", error);
    }
    try {
      // Fetch settings data
      const res = await fetch(`${configuration.APP_URL_CONTENTS}/settings?lob=${LOB_VALUE}`);
      datasetting = await res.json();
    } catch (error) {
      console.error("Failed to fetch settings data:", error);
    }
     
    let result ={
      footer:footer,
      widgetmenu:widgetmenu ? widgetmenu:[],
      datasetting:datasetting ? datasetting:[],
    }
    
    return result;
  } catch (error) {
    console.error('Error fetching footer:', error);
    return null; // Handle error gracefully, return null or some default value
  }
}
export { MenuFunction , FooterFunction};